/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import { useExpressWifi } from '../hooks/useExpressWifi';
// import axios from "axios";

const ExpressWifiPayment = () => {
  const {
    retailer,
    onChange,
    payAgain,
    payRetailService,
    message,
  } = useExpressWifi();

  const {
    firstName,
    lastName,
    businessName,
    phoneNumber,
    email,
    amount,
  } = retailer;

  const submitForm = () => {
    return (
      firstName.length > 0 &&
      lastName.length > 0 &&
      businessName.length > 0 &&
      phoneNumber.length > 0 &&
      email.length > 0 &&
      amount >= 100
    );
  };

  const displayForm = () => {
    if (message === 'Payment Successful') {
      return (
        <div className="make-payment bg-light p-3">
          <strong style={{ align: 'center' }}>
            <label className="copy-font m-0 p-0">
              Payment successful! Please contact the accounts unit on
              internet@wifi.com.ng or 02012291000
            </label>
          </strong>
          <hr />
          <form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              payAgain();
            }}
          >
            <div className="col-md-12 text-center">
              <button type="submit" className="btn btn-success w-75 m-0">
                Another Payment?
              </button>
            </div>
          </form>
        </div>
      );
    }
    return (
      <Fragment>
        <div className="col-12">
          <h3 className="text-center mb-1 mt-2" style={{ color: '#3b5898' }}>
            <strong>Make Payment</strong>
          </h3>
          <div className="make-payment bg-light p-3">
            <form
              className="form-horizontal"
              onSubmit={(e) => {
                e.preventDefault();
                payRetailService(retailer);
              }}
            >
              <div className="form-group">
                <div className="col-md-12 d-flex flex-wrap p-0">
                  <div className="col-md-6 col-12 mb-3">
                    <label className="copy-font m-0 p-0">
                      <strong>First Name</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      name="firstName"
                      onChange={onChange}
                      value={firstName}
                      required
                    />
                  </div>
                  <div className="col-md-6 col-12 mb-3">
                    <label className="copy-font m-0 p-0">
                      <strong>Last Name</strong>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      name="lastName"
                      onChange={onChange}
                      value={lastName}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="copy-font m-0 p-0">
                    <strong>Business Name</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Business Name"
                    name="businessName"
                    onChange={onChange}
                    value={businessName}
                    required
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label className="copy-font m-0 p-0">
                    <strong>Phone Number</strong>
                  </label>
                  <input
                    type="tel"
                    className="form-control phone-prefix pl-5 pb-1"
                    pattern="[0-9]{10}"
                    placeholder="8123456789"
                    name="phoneNumber"
                    maxLength="12"
                    title="8123456789"
                    onChange={onChange}
                    value={phoneNumber}
                    required
                  />
                  <span className="phone-part d-md-block d-none">+234</span>
                  <span className="phone-part-sm d-md-none d-sm-block">
                    +234
                  </span>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="copy-font m-0 p-0">
                    <strong>Email Address </strong>
                    <small>(Optional)</small>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    name="email"
                    pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\b"
                    onChange={onChange}
                    value={email}
                    required
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label className="copy-font m-0 p-0">
                    <strong>Amount</strong>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Amount"
                    name="amount"
                    step="100"
                    onChange={onChange}
                    value={amount}
                    required
                  />
                </div>
              </div>
              <div className="form-group text-center">
                <button
                  type="submit"
                  className="btn btn-success m-0"
                  style={{ width: '93%' }}
                  disabled={!submitForm()}
                >
                  <strong>Pay</strong>
                </button>
              </div>
            </form>
          </div>
          <div className="text-center mt-1">
            <p>
              <small>Powered by </small>
              <span>
                <img
                  src="https://res.cloudinary.com/teewhy/image/upload/v1542979509/Tizeti-Logo.png"
                  alt=""
                  style={{ width: '23%' }}
                />
              </span>
            </p>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <div className="col-12">
      <div className="col-md-6 col-lg-4 col-12 mt-4 ml-auto mr-auto make-payment">
        <div className="wifilogo mb-3 text-center">
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1579775394/facebookLogo.png"
            alt="logo"
            style={{ width: '38%' }}
          />
        </div>
        {displayForm()}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ExpressWifiPayment;
