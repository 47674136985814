/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import '../styles/style.css';
import { useApp } from '../hooks/usePayment';

const App = () => {
  const {
    pay,
    onChange,
    payForService,
    message,
    makeAnotherPayment,
  } = useApp();
  const { firstname, lastName, email, amount } = pay;

  const submitForm = () => {
    return (
      firstname.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      amount.length > 0
    );
  };

  const showForm = () => {
    if (message === 'Payment Successful') {
      return (
        <div className="make-payment bg-light p-3">
          <strong style={{ align: 'center' }}>
            <label className="copy-font m-0 p-0">
              Payment successful! Please contact the accounts unit on
              internet@wifi.com.ng or 02012291000
            </label>
          </strong>
          <hr />
          <form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              makeAnotherPayment();
            }}
          >
            <div className="col-md-12 text-center">
              {/* eslint-disable-next-line react/button-has-type */}
              <button className="btn btn-success w-75 m-0">
                Another Payment?
              </button>
            </div>
          </form>
        </div>
      );
    }
    return (
      <Fragment>
        <h4 className="text-center mb-4 mt-3">
          <strong>Make Payment</strong>
        </h4>
        <div className="make-payment bg-light p-3">
          <form
            className="form-horizontal"
            onSubmit={(e) => {
              e.preventDefault();
              payForService(pay);
            }}
          >
            <div className="form-group">
              <div className="col-md-12 d-flex flex-wrap mb-3">
                <div className="col-md-6 col-12 mb-3">
                  <label className="copy-font m-0 p-0">
                    <strong>First Name</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="firstname"
                    onChange={onChange}
                    value={firstname}
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <label className="copy-font m-0 p-0">
                    <strong>Last Name</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    name="lastName"
                    onChange={onChange}
                    value={lastName}
                  />
                </div>
              </div>
              <div className="col-md-12 d-flex mb-3 flex-wrap">
                <div className="col-md-6 col-12 mb-3">
                  <label className="copy-font m-0 p-0">
                    <strong>Email Address</strong>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Main Email Address"
                    name="email"
                    onChange={onChange}
                    value={email}
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <label className="copy-font m-0 p-0">
                    <strong>Amount</strong>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Amount"
                    name="amount"
                    onChange={onChange}
                    value={amount}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 text-center">
              {/* eslint-disable-next-line react/button-has-type */}
              <button
                className="btn btn-success w-75 m-0"
                disabled={!submitForm()}
              >
                <strong>Pay</strong>
              </button>
              <div className="mt-3">
                <a href="/express-wifi">Express Wifi retailer pay here</a>
              </div>
            </div>
          </form>
        </div>
      </Fragment>
    );
  };
  return (
    <div className="col-12">
      <div className="col-md-6 col-lg-4 col-12 mt-5 ml-auto mr-auto">
        <div className="wifilogo mb-3 text-center">
          <img
            src="https://res.cloudinary.com/teewhy/image/upload/v1542988856/wifilogo.png"
            alt="logo"
          />
        </div>
        {showForm()}
      </div>
      <ToastContainer />
    </div>
  );
};

export default App;
