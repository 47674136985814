//tizeti's own
export const PAYSTACK_LIVE_KEY_URL = 'pk_live_48dd0c126b30e969b1b7eff9f27ded163647f4c6';
export const PAYSTACK_TEST_KEY_URL = 'pk_test_e1929473ff607af44d68b320871309eba86d377f';
export const PAYSTACK_SK_TEST = 'sk_test_0cf58b55fa999104ff8f2576bd07648967463b6b';
export const PAYSTACK_SK_LIVE = 'sk_live_b4ecdca187ecb3c756330efbf6979f8ea523d279';
export const TIZETI_CALLBACK_URL = "https://tizeti.com/williams_rd/tizetipayverify/index.php";
export const FRONT_ACCOUNTING_URL = "https://legacy.tizeti.com/frontaccountapi/account.php";
export const EXPRESSWIFIENDPOINT = "https://legacy.tizeti.com/expresswifiapi/api.php"
export const EMAIL_NOTIFIER_URL = "https://legacy.tizeti.com/operations_api/modified_operations_api.php"

