/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import { toast } from 'react-toastify';
import { PAYSTACK_TEST_KEY_URL, PAYSTACK_SK_TEST, PAYSTACK_LIVE_KEY_URL, PAYSTACK_SK_LIVE, FRONT_ACCOUNTING_URL, EXPRESSWIFIENDPOINT, EMAIL_NOTIFIER_URL } from '../keys';
import axios from 'axios'
import qs from 'qs';
// eslint-disable-next-line import/no-extraneous-dependencies
export const useExpressWifi = () => {
  const retailerState = {
    firstName: '',
    lastName: '',
    businessName: '',
    phoneNumber: '',
    email: '',
    amount: 0,
  };

  const [retailer, setRetailer] = useState(retailerState);
  const [message, setMessage] = useState('');




  const onChange = (e) => {
    const { name, value } = e.target;
    setRetailer({
      ...retailer,
      [name]: value,
    });
  };

  const payAgain = () => {
    setMessage('');
  };

  const payRetailService = async (data) => {
    const { amount, email, firstName, lastName, phoneNumber } = data;
    const multipliedAmount = amount * 100;
    const newAmount = 0.014198947 * multipliedAmount + multipliedAmount;
    const handler = window.PaystackPop.setup({
      key: `${PAYSTACK_LIVE_KEY_URL}`,
      email,
      amount: Math.round(newAmount),
      ref: `TZT_XPW_${phoneNumber}_${Math.floor(
        Math.random() * 1000000000000000 + 1,
      )}`,
      firstName,
      lastName,
      metadata: {
        custom_fields: [
          {
            email,
            amount: newAmount,
            phoneNumber,
          },
        ],
      },
      callback(response) {


        if (response.status == 'success') {

       
          const verificationURL = `https://api.paystack.co/transaction/verify/${response.reference}`
          const customerReference = response.reference

          const options = {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${PAYSTACK_SK_LIVE}` },
            data: [],
            url: verificationURL
          };


          axios(options)
            .then((response) => {

              if (response.data.data.status == "success" && response.data.data.domain == "live") {


                const requestParameters = {
                  'action': 'createCustomerPaymentDynamic',
                  'TB_PREF': '0_',
                  'user_id': 'cgate.tzt',
                  'password': 'megamound',
                  'debtormac': 'EXPRESSWIFI',
                  'pmtamount': response.data.data.amount / 100,
                  'reference': response.data.data.reference,
                  'bank_account': 1208,
                  'bank_account_gl': 4003,
                  'addr': 'Express Wifi Payment from Pay.tizeti.com (Search for customer on Paystack with reference)'

                }
                let options = {
                  method: 'POST',
                  headers: { 'content-type': 'application/x-www-form-urlencoded' },
                  data: qs.stringify(requestParameters),
                  url: FRONT_ACCOUNTING_URL
                }

                axios(options).then((response) => {
               

                  const notificationData = {
                    'action': 'sendEmailHelper',
                    'from': 'expresswifi@tizeti.com',
                    'subject': 'Retailer Payment successfully posted to front Accounting',
                    'to': 'account@tizeti.com',
                    'message': `TRANSACTION ON Paystack REF : ${customerReference} was succesfully posted to Front accounting `,

                  }

                  let noticationOptionForFrontAccounting = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    data: qs.stringify(notificationData),
                    url: EMAIL_NOTIFIER_URL
                  }

                  axios(noticationOptionForFrontAccounting)


                })

                let retailerRechargeAmount = 0
                let vAmount = parseInt(amount)


                if (vAmount < 15000) {
                  retailerRechargeAmount = vAmount + (vAmount * 0.1)
                }
                else if (vAmount >= 15000 || vAmount < 100000) {
                  retailerRechargeAmount = vAmount + (vAmount * 0.2)
                }
                else {
                  retailerRechargeAmount = vAmount + (vAmount * 0.3)

                }



                let requestFBParameters2 = {
                  'action': 'retailer_recharge',
                  'paid_amount': retailerRechargeAmount,
                  'transaction_id': response.data.data.reference,
                  'retailer_phone': `+234${phoneNumber}`,
                  'extra': 'EXPRESSWIFI Payment from Pay.tizeti.com',

                };
                options = {
                  method: 'POST',
                  headers: { 'content-type': 'application/x-www-form-urlencoded' },
                  data: qs.stringify(requestFBParameters2),
                  url: EXPRESSWIFIENDPOINT
                }

                axios(options).then((response) => {

                  if (response.data.success) {
                    const notificationDataPass = {
                      'action': 'sendEmailHelper',
                      'from': 'expresswifi@tizeti.com',
                      'subject': 'Retailer Payment was successfully  credited on XPP',
                      'to': 'account@tizeti.com',
                      'message': `TRANSACTION ON Paystack REF : ${customerReference}  was succesfully credited on XPP, `,

                    }

                    let noticationOptionForFrontAccountingPass = {
                      method: 'POST',
                      headers: { 'content-type': 'application/x-www-form-urlencoded' },
                      data: qs.stringify(notificationDataPass),
                      url: EMAIL_NOTIFIER_URL
                    }

                    axios(noticationOptionForFrontAccountingPass)
                  } else {
                    const notificationDataFail = {
                      'action': 'sendEmailHelper',
                      'from': 'expresswifi@tizeti.com',
                      'subject': 'Retailer Payment Failed to get credited on XPP',
                      'to': 'account@tizeti.com',
                      'message': `TRANSACTION ON Paystack REF : ${customerReference}  failed to find retailer and account was not credited on XPP, please reach out to the customer `,

                    }

                    let noticationOptionForFrontAccountingFail = {
                      method: 'POST',
                      headers: { 'content-type': 'application/x-www-form-urlencoded' },
                      data: qs.stringify(notificationDataFail),
                      url: EMAIL_NOTIFIER_URL
                    }

                    axios(noticationOptionForFrontAccountingFail)
                  }


                })









              }






            })














          setMessage('Payment Successful Test');
          setRetailer(retailerState);
        } else {

          toast.info('Payment not successful!');
        }
      },
      onClose() {
        alert('window closed');
      },
    });
    handler.openIframe();
  };

  return { retailer, onChange, payAgain, payRetailService, message };
};
