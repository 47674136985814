/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify';
import { PAYSTACK_LIVE_KEY_URL } from '../keys';

export const useApp = () => {
  const payState = {
    firstname: '',
    lastName: '',
    email: '',
    amount: '',
  };

  const [pay, setPay] = useState(payState);
  const [message, setMessage] = useState('');

  const onChange = (e) => {
    const { name, value } = e.target;
    setPay({
      ...pay,
      [name]: value,
    });
  };

  const makeAnotherPayment = () => {
    setMessage('');
  };

  const payForService = async (data) => {
    const { amount, email, firstname, lastName } = data;
    const multipliedAmount = amount * 100;
    const newAmount = 0.014198947 * multipliedAmount + multipliedAmount;
    const handler = window.PaystackPop.setup({
      key: `${PAYSTACK_LIVE_KEY_URL}`,
      email,
      amount: Math.round(newAmount),
      ref: `TZT_PAY_TIZETI.COM${Math.floor(
        Math.random() * 1000000000000000 + 1,
      )}`, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
      firstname,
      lastname: lastName,
      // label: "Optional string that replaces customer email"
      metadata: {
        custom_fields: [
          {
            email,
            amount: newAmount,
          },
        ],
      },
      callback(response) {
        if (response.status === 'success') {
          setMessage('Payment Successful');
          setPay(payState);
        } else {
          toast.info('Payment not successful!');
        }
      },
      onClose() {
        // alert('window closed');
      },
    });
    handler.openIframe();
  };

  return { pay, onChange, payForService, message, makeAnotherPayment };
};
